import Button, {
    ButtonTypes,
} from 'components/shared/generic/button/presentational/Button';
import LogoWhite from '_content/images/logos/Suits Me Logo.png';
import { LOGIN } from '../../../../constants/shared/routes';
import { SUITS_ME_APPLY, UTM_QUERY } from 'lib/constants/routes';

const url = SUITS_ME_APPLY + UTM_QUERY;

export const Main = () => {
    return (
        <div className="loginContainer">
            <img src={LogoWhite} alt="Logo" className="logo mt-4" />
            <div className="applyButtons d-flex-col-center mt-3">
                <Button type={ButtonTypes.BUTTON} to={LOGIN}>
                    Login
                </Button>
                <Button
                    type={ButtonTypes.BUTTON}
                    href={url}
                    className="applyBtn"
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};
